import { ApiError } from "api/api";
import { useApi } from "api/context";
import { setupInterceptors } from "api/interceptors";
import { ApiOkResponse, ApiResponse, create } from "apisauce";
import { AxiosError } from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { IAccount } from "types/data/IAccount";
import { throwOnError } from "utils/queries";

export const useAccountLoggedGetQuery = (
  opts?: UseQueryOptions<IAccount, AxiosError<ApiError>>
) => {
  return useQuery<IAccount, AxiosError<ApiError>>(
    "accountLogged",
    async () => {
      // We're not using the shared API object to query the current logged user. If we
      // were to use the shared API object, that would create an infinite dependency
      // loop:
      //   useAccountGetLoggedQuery() -> useAPI() -> useAuth() -> useAccountGetLoggedQuery()
      const api = create({ baseURL: process.env.API_BASE_URL });
      setupInterceptors(api);
      const data = api.get("/account") as Promise<
        ApiResponse<{ account: IAccount }, ApiError>
      >;
      return data.then(throwOnError).then((res) => res.data.account);
    },

    opts
  );
};

export const useAccountLoggedUpdateMutation = (
  accountId: string,
  opts?: UseQueryOptions<
    ApiOkResponse<{ account: IAccount }>,
    AxiosError<ApiError>
  >
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<
    ApiOkResponse<{ account: IAccount }>,
    AxiosError<ApiError>,
    Partial<IAccount>
  >(
    async (params) => {
      return api.account.update(accountId, params).then(throwOnError);
    },
    {
      onSuccess: (data) => {
        if (data !== undefined) {
          const newUser = data.data?.account as IAccount;
          queryClient.setQueryData("accountLogged", () => newUser);
          queryClient.invalidateQueries("accountLogged");
        }
      },
      ...opts,
    }
  );
};

export const useAccountGetByIdQuery = (
  accountId: string,
  opts?: UseQueryOptions<IAccount, AxiosError<ApiError>>
) => {
  const api = useApi();
  return useQuery<IAccount, AxiosError<ApiError>>(
    [`accounts`, accountId],
    async () => {
      return api.account
        .getAccountById(accountId)
        .then(throwOnError)
        .then((res) => res.data.account);
    },
    opts
  );
};

export const useAccountStartOTPUpdate = (
  opts?: UseQueryOptions<
    ApiOkResponse<{ status: string }>,
    AxiosError<ApiError>
  >
) => {
  const api = useApi();
  return useMutation<
    ApiOkResponse<{ status: string }>,
    AxiosError<ApiError>,
    { phone: string | null; email: string | null }
  >(async (params) => {
    return api.account.startOTPUpdate(params).then(throwOnError);
  }, opts);
};

export const useAccountFinishOTPUpdate = (
  opts?: UseQueryOptions<
    ApiOkResponse<{ status: string }>,
    AxiosError<ApiError>
  >
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<
    ApiOkResponse<{ status: string }>,
    AxiosError<ApiError>,
    { otp: string }
  >(
    async (params) => {
      return api.account.finishOTPUpdate(params).then(throwOnError);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("accountLogged");
      },
      ...opts,
    }
  );
};

export const useAccountUpdateMutation = (
  accountId: string,
  opts?: UseQueryOptions<
    ApiOkResponse<{ account: IAccount }>,
    AxiosError<ApiError>
  >
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<
    ApiOkResponse<{ account: IAccount }>,
    AxiosError<ApiError>,
    Partial<IAccount>
  >(
    async (params) => {
      return api.account.update(accountId, params).then(throwOnError);
    },
    {
      onSuccess: (data) => {
        if (data !== undefined) {
          const newUser = data.data?.account as IAccount;
          queryClient.setQueryData(["accounts"], (accounts?: IAccount[]) => {
            return [
              newUser,
              ...(accounts?.filter((account) => account.id !== newUser.id) ||
                []),
            ];
          });
          queryClient.invalidateQueries("accounts");
          queryClient.invalidateQueries("accountLogged");
        }
      },
      ...opts,
    }
  );
};

export const useAccountDeleteMutation = (
  opts?: UseQueryOptions<
    ApiOkResponse<{ account: IAccount }>,
    AxiosError<ApiError>
  >
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation<
    ApiOkResponse<{ account: IAccount }>,
    AxiosError<ApiError>
  >(
    async () => {
      return api.account.delete().then(throwOnError);
    },
    {
      onSuccess: () => {
        queryClient.setQueryData("accountLogged", () => undefined);
      },
      ...opts,
    }
  );
};
