import { Daphne, initDaphne } from "@syadem/daphne-js";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { ApiClient, createAPIClient } from "./api";
import { useAuth } from "./auth/auth";
import daphnejsMock from "./mock/daphnejsMock.json";

/*
  API will be accessible through React Context
*/
// @ts-ignore
export const ApiContext = React.createContext<ApiClient>(null);

export function ApiProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const apiClient: ApiClient = useMemo(() => createAPIClient(auth), [auth]);

  return (
    <ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>
  );
}
export const useApi = () => React.useContext<ApiClient>(ApiContext);

export interface IDaphneContext {
  daphne: Daphne | null;
  isLoading: boolean;
}
// @ts-ignore
export const DaphneContext = React.createContext<IDaphneContext>(null);
export const DaphneProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [data, setData] = useState<Daphne | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const datas = await initDaphne(
        typeof window !== "undefined" && window?.Cypress
          ? {
              jsonFile: daphnejsMock,
            }
          : {}
      );
      if (datas) {
        setData(datas);
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <DaphneContext.Provider value={{ daphne: data, isLoading }}>
      {children}
    </DaphneContext.Provider>
  );
};
export const useDaphne = () => React.useContext<IDaphneContext>(DaphneContext);
