import { useKeycloak } from "@react-keycloak/web";
import { AxiosError } from "axios";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { IAccount } from "types/data/IAccount";
import { ApiError } from "../api";
import { IAccountType } from "./../../types/data/enums/IAccountType";
import { useAccountLoggedGetQuery } from "./../queries/account";

export const APP_REALM = "citizen";

export interface AuthContext {
  currentUser: {
    isLoading: boolean;
    isError: boolean;
    data?: IAccount;
    error: AxiosError<ApiError> | null;
    isInCorrectScope?: boolean;
  };
  getAccessToken(): string | undefined;
  logout(): Promise<void>;
  goToCitizenAccountManagement(): Promise<void>;
}

function useKeycloakAuth(): AuthContext {
  const { keycloak, initialized } = useKeycloak();
  const router = useRouter();

  useEffect(() => {
    if (initialized && !keycloak?.authenticated) {
      keycloak?.login();
    }
  }, [initialized, keycloak]);

  // TODO: Replace all this init logic by calling the backend.
  // Should b elike before except the backend will handle keycloak
  // init and login.
  const account: IAccount | undefined = useMemo(
    () =>
      keycloak?.idTokenParsed
        ? {
            id: keycloak.idTokenParsed.sub as string,
            email: keycloak.idTokenParsed.email,
            // TODO: configure adding a real phone number in the identification token
            phone: "dummy_phone_number",
            first_name: keycloak.idTokenParsed.given_name,
            last_name: keycloak.idTokenParsed.family_name,
            account_type: IAccountType.Citizen,
            // TODO: use real values from token (maybe configure keycloak-side)
            created_at: "2022-01-01",
            updated_at: "2022-01-01",

            notifications: [],

            // Consent
            allow_dsp_usage: false,
            allow_covid_data_usage: false,
            allow_deletion_after_death: false,
            allow_cve_update: true,
          }
        : undefined,
    [keycloak?.idTokenParsed]
  );

  const isUserInCorrectScope = keycloak?.authenticated;

  useEffect(() => {
    if (account && !isUserInCorrectScope) {
      router.push({ pathname: getUserHomePath(account) });
    }
  }, [isUserInCorrectScope, account, router]);

  const auth: AuthContext = useMemo(
    () => ({
      currentUser: {
        isLoading: !initialized,
        isError: !keycloak?.authenticated,
        data: keycloak?.idTokenParsed ? account : undefined,
        error: null,
        isInCorrectScope: isUserInCorrectScope,
      },
      getAccessToken: () => keycloak?.token,
      logout: async () => await keycloak?.logout(),
      goToCitizenAccountManagement: async () => {
        await keycloak.accountManagement();
      },
    }),
    [account, initialized, isUserInCorrectScope, keycloak]
  );

  return auth;
}

function useClassicAuth(): AuthContext {
  const currentAccountQuery = useAccountLoggedGetQuery({
    staleTime: 1000 * 60 * 30, // 30 min
    cacheTime: 1000 * 60 * 60, // 60 min
  });
  const currentUser =
    !currentAccountQuery.isLoading && currentAccountQuery.data;
  const router = useRouter();
  const scope = getScope(router.pathname);

  const isUserInCorrectScope =
    currentUser && scope === currentUser.account_type;

  useEffect(() => {
    if (currentUser && scope && !isUserInCorrectScope) {
      router.push({ pathname: getUserHomePath(currentUser) });
    }
  }, [isUserInCorrectScope, currentUser, router, scope]);

  return {
    currentUser: {
      isLoading: currentAccountQuery.isLoading,
      isError: currentAccountQuery.isError,
      data: currentAccountQuery.data,
      error: currentAccountQuery.error,
      isInCorrectScope: isUserInCorrectScope,
    },
    getAccessToken: () => undefined,
    logout: async () => {
      // No-op
    },
    goToCitizenAccountManagement: async () => {
      await router.push("/citizen/account");
    },
  };
}

export const useAuth =
  typeof window !== "undefined" &&
  !window?.Cypress &&
  process.env["NEXT_PUBLIC_AUTHENTICATION_METHOD"] == "keycloak"
    ? useKeycloakAuth
    : useClassicAuth;

export const getUserHomePath = (user: IAccount) => {
  if (user.account_type === IAccountType.HealthProfessionnal) {
    return "/health-professional";
  } else {
    return "/citizen";
  }
};

export const getScope = (url: string): IAccountType | undefined => {
  if (url.indexOf("health-professional") > -1) {
    return IAccountType.HealthProfessionnal;
  }
  if (url.indexOf("citizen") > -1) {
    return IAccountType.Citizen;
  }
};

export const getScopeFromWindow = () => {
  return typeof window !== "undefined"
    ? getScope(window.location.pathname)
    : undefined;
};
