import clsx from "clsx";
import { BaseRouter } from "next/dist/shared/lib/router/router";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { Children } from "react";
import { UrlObject } from "url";

export interface NextLinkProps extends Omit<LinkProps, "href"> {
  children: React.ReactElement;
  href:
    | string
    | { pathname: string; query?: { [index: string]: any }; as?: string };
  partialMatch?: boolean;
  className?: string;
}

const baseUrl = new URL("https://example.org/"); // Dummy base URL

export const removeTrailingSlash = (str?: string | null) => {
  return str && str.endsWith("/") ? str.slice(0, -1) : str;
};

const hrefObjectToURL = (
  urlObject: UrlObject = { pathname: "", query: {} }
) => {
  const { pathname, query } = urlObject;
  if (!query || Object.keys(query).length === 0) {
    return new URL(pathname || "", baseUrl);
  }
  const replacedHref = (pathname || "").replace(
    /\[([a-zA-Z]+)\]/g,
    (fullMatch, key) => {
      // @ts-ignore
      const value = query[key];
      if (!value) {
        throw new Error(`expected value in query object for ${fullMatch}`);
      }
      return value;
    }
  );
  return new URL(replacedHref, baseUrl);
};

export type RouteMatcherParams = {
  router: BaseRouter;
  href: LinkProps["href"];
  as?: LinkProps["as"];
  partialMatch?: boolean;
};

export const isMatchingPathnameExactly = ({
  router,
  href,
  as,
  partialMatch,
}: RouteMatcherParams): boolean => {
  const to = as != null ? as : href;
  const expectedRoute = (
    typeof to === "string" ? new URL(to, baseUrl) : hrefObjectToURL(to)
  ).pathname;
  const currentRoute = new URL(router.asPath, baseUrl).pathname;

  if (partialMatch) {
    return currentRoute.indexOf(expectedRoute) === 0;
  }
  return currentRoute === expectedRoute;
};

export const NextLink: React.FC<NextLinkProps> = ({
  children,
  passHref = true,
  className,
  ...props
}) => {
  const router = useRouter();
  const child = Children.only(children);

  const matchingFn = isMatchingPathnameExactly;

  const isActive = matchingFn({
    router: router,
    href: props.href,
    as: props.as,
    partialMatch: props.partialMatch,
  });

  return (
    <Link passHref={passHref} {...props}>
      {React.cloneElement(child, {
        isActive: isActive,
        className: clsx(className, child.props.className),
      })}
    </Link>
  );
};
