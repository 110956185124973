import { appName } from "@config";
import styles from "@theme/components/Logo.module.css";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import * as React from "react";
import { getThemeAssetPath } from "utils/next";

export interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className, ...rest }) => {
  const { t } = useTranslation();
  return (
    <img
      className={clsx(styles.logo, className)}
      src={getThemeAssetPath("logo.svg")}
      alt={t("common:logo", { appName: appName })}
      {...rest}
    />
  );
};
