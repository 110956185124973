import dayjsLib from "dayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { Translate } from "next-translate";
dayjsLib.extend(customParseFormat);
dayjsLib.extend(utc);
dayjsLib.extend(localizedFormat);
dayjsLib.extend(localeData);

export const dayjs = dayjsLib;

/*
  https://silvantroxler.ch/2018/browser-detect-builtin-datepicker/
*/
export const browserHasDatePicker = (): boolean => {
  if (typeof window === "undefined") {
    return true;
  }
  const input = document.createElement("input");
  input.setAttribute("type", "date");
  input.value = "2018-01-01";
  return !!input.valueAsDate;
};

export const calculateAge = (
  end: Date,
  begin: Date,
  withMonths: boolean,
  t: Translate
): string => {
  const endDate = dayjs(end);
  const beginDate = dayjs(begin);
  const daysDiff = endDate.diff(beginDate, "day");
  const monthsDiff = endDate.diff(beginDate, "month");
  const yearsDiff = endDate.diff(beginDate, "year");

  if (yearsDiff == 0) {
    if (monthsDiff == 0) {
      return `${daysDiff} ${t("common:age.day", { count: daysDiff })}`;
    } else {
      return `${monthsDiff} ${t("common:age.month", { count: monthsDiff })}`;
    }
  } else {
    const diffMonth = monthsDiff - yearsDiff * 12;
    if (withMonths && diffMonth !== 0) {
      return `${yearsDiff} ${t("common:age.year", {
        count: yearsDiff,
      })} ${t("common:and")} ${diffMonth} ${t("common:age.month", {
        count: diffMonth,
      })}`;
    } else {
      return `${yearsDiff} ${t("common:age.year", { count: yearsDiff })}`;
    }
  }
};
