import { isNotEmpty } from "./array";
export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const maybeJoin = (stringA?: string, stringB?: string, sep = "") => {
  return [stringA, stringB].filter(isNotEmpty).join(sep);
};

export const dotJoin = (stringA?: string, stringB?: string) =>
  maybeJoin(stringA, stringB, ".");
