import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { IProvider } from "types/data/IProvider";

export interface ApiAuthClient {
  getProviders: () => Promise<
    ApiResponse<{ providers: IProvider[] }, ApiError>
  >;
}

export const createAuthEndpoint = (api: ApisauceInstance): ApiAuthClient => {
  return {
    getProviders: () => {
      return api.get("/auth/providers");
    },
  };
};
