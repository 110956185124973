
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { LayoutTree } from "@moxy/next-layout";
import { SSRProvider } from "@react-aria/ssr";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/node";
import "@styles/globals.css"; // Default styles
import { OverlayProvider } from "@syadem/ui-components";
import "@theme/globals.css"; // Theme overrides
import styles from "@theme/pages/app.module.css";
import { APP_REALM } from "api/auth/auth";
import { ApiProvider, DaphneProvider } from "api/context";
import { createMirageProxyServer } from "api/mirage/proxyServer";
import { createMirageDevServer } from "api/mirage/server";
import axios, { AxiosError } from "axios";
import { Head } from "components/Head";
import Keycloak from "keycloak-js";
import useTranslation from "next-translate/useTranslation";
import type { AppContext } from "next/app";
import { AppProps } from "next/dist/shared/lib/router/router";
import { Router } from "next/router";
import CustomError from "pages/_error";
import React, { useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { TunnelProvider } from "react-tunnels";
import { dayjs } from "utils/dayjs";
import { GlobalErrorProvider } from "utils/hooks/usePageErrors";
import { isServer } from "utils/next";

const timesToRetry = 2;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //@ts-ignore
      retry: (failureCount, error: AxiosError<any> | Response) => {
        const status = axios.isAxiosError(error)
          ? error.response?.status
          : error.status;
        if (!status) {
          throw error;
        }
        if (
          status === 404 ||
          status === 500 ||
          status === 422 ||
          status === 401
        ) {
          return false;
        }
        if (failureCount >= timesToRetry) {
          return false;
        }
        return true;
      },
    },
  },
});

if (process.env.USE_MOCKED_API === "true" && !isServer()) {
  if (typeof window !== "undefined" && window.Cypress) {
    createMirageProxyServer();
  } else {
    createMirageDevServer({
      environment: "development",
      urlPrefix: process.env.API_BASE_URL,
    });
  }
}

Router.events.on("routeChangeComplete", () => {
  const scrollContainer = document.getElementById("scrollContainer");
  if (scrollContainer) {
    scrollContainer.scroll({ top: 0 });
  }
});

Sentry.init({
  enabled: !!process.env.SENTRY_DSN && process.env.NODE_ENV === "production",
  environment: process.env.ENVIRONMENT,
  release: process.env.RELEASE,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const keycloak: Keycloak | undefined = useMemo(
    () =>
      typeof window !== "undefined" && !window.Cypress
        ? new Keycloak({
            clientId: process.env["NEXT_PUBLIC_AUTH_CLIENT"] as string,
            url: process.env["NEXT_PUBLIC_AUTH_URL"],
            realm: APP_REALM,
          })
        : undefined,
    []
  );

  return keycloak &&
    process.env["NEXT_PUBLIC_AUTHENTICATION_METHOD"] == "keycloak" ? (
    <ReactKeycloakProvider authClient={keycloak}>
      {children}
    </ReactKeycloakProvider>
  ) : (
    <>{children}</>
  );
};

function MyApp({ Component, pageProps }: AppContext & { pageProps: AppProps }) {
  const { lang } = useTranslation();
  const [error, setError] = React.useState<number>();

  React.useEffect(() => {
    dayjs.locale(lang);
  }, [lang]);

  React.useEffect(() => {
    setError(undefined);
  }, [Component]);

  return (
    <AuthProvider>
      <DaphneProvider>
        {/* React-query https://react-query.tanstack.com/guides/ssr#using-nextjs */}
        <QueryClientProvider client={queryClient}>
          <ApiProvider>
            <Hydrate state={pageProps.dehydratedState}>
              {/* React-aria SSRProvider */}
              <SSRProvider>
                <Head />
                {/* React-aria OverlayProvider, needed for @syadem/ui-components Dialog components */}
                <OverlayProvider className={styles.app}>
                  <GlobalErrorProvider value={{ error, setError }}>
                    <TunnelProvider>
                      <LayoutTree
                        Component={
                          error
                            ? (props: any) => (
                                <CustomError statusCode={error} {...props} />
                              )
                            : Component
                        }
                        pageProps={pageProps}
                      />
                    </TunnelProvider>
                  </GlobalErrorProvider>
                </OverlayProvider>
              </SSRProvider>
            </Hydrate>
          </ApiProvider>
        </QueryClientProvider>
      </DaphneProvider>
    </AuthProvider>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  