import {
  Configuration,
  HealthRecordApi,
  RecordSharingApi,
  VaccinationActApi,
} from "@syadem/kairos-citizen-js";
import { create } from "apisauce";
import { AxiosError } from "axios";
import { AuthContext } from "./auth/auth";
import { ApiAccountClient, createAccountEndpoint } from "./endpoints/account";
import { ApiAuthClient, createAuthEndpoint } from "./endpoints/auth";
import { ApiCountryClient, createCountryEndpoint } from "./endpoints/country";
import {
  ApiEventLogClient,
  createEventLogEndpoint,
} from "./endpoints/eventLog";
import {
  ApiHealthProfileClient,
  createHealthProfileEndpoint,
} from "./endpoints/healthProfile";
import {
  ApiHealthRecordAccessControlClient,
  createHealthRecordAccessControlEndpoint,
} from "./endpoints/healthRecordAccessControl";
import {
  ApiMedicalOfficeClient,
  createMedicalOfficeEndpoint,
} from "./endpoints/medicalOffice";
import {
  ApiNotificationClient,
  createNotificationEndpoint,
} from "./endpoints/notification";
import {
  ApiPreventionActClient,
  createPreventionActEndpoint,
} from "./endpoints/preventionAct";
import {
  ApiProfileLinkClient,
  createProfileLinkEndpoint,
} from "./endpoints/profileLink";
import { ApiVaccineClient, createVaccineEndpoint } from "./endpoints/vaccine";
import { setupInterceptors } from "./interceptors";

export interface ApiClient {
  auth: ApiAuthClient;
  healthRecord: HealthRecordApi;
  vaccinationAct: VaccinationActApi;
  preventionAct: ApiPreventionActClient; //TODO delete when vaccinationAct is done
  vaccine: ApiVaccineClient;
  profileLink: ApiProfileLinkClient;
  account: ApiAccountClient;
  healthRecordAccessControl: ApiHealthRecordAccessControlClient;
  notification: ApiNotificationClient;
  country: ApiCountryClient;
  eventLog: ApiEventLogClient;
  medicalOffice: ApiMedicalOfficeClient;
  healthProfile: ApiHealthProfileClient;
  sharingToken: RecordSharingApi;
}

export interface BackendErrorObject {
  full_messages?: string;
  send_mail?: boolean;
  req_id?: string;
  [key: string]: any;
}

export interface ApiError {
  errors?: BackendErrorObject;
}

export const isApiError = (error: any): error is AxiosError<ApiError> => {
  return (
    error?.isAxiosError && (error as AxiosError<any>).response?.data?.errors
  );
};

export const getApiErrorMessage = (error: AxiosError<ApiError>) => {
  return error.response?.data?.errors?.full_messages;
};

export const hasToDisplaySendMail = (error: AxiosError<ApiError>) => {
  return error.response?.data?.errors?.send_mail;
};

export const getApiErrorSendMailDetails = (error: AxiosError<ApiError>) => {
  if (
    !error?.response?.data?.errors?.req_id ||
    !error?.response?.data?.errors?.full_messages
  ) {
    throw new Error("Can't get sendMailDetails from API Error");
  }
  return {
    req_id: error.response.data.errors.req_id,
    message: error.response.data.errors.full_messages,
  };
};

export const createAPIClient = (
  auth: AuthContext | undefined = undefined
): ApiClient => {
  const kairosCitizenConfiguration = new Configuration({
    basePath: process.env.API_BASE_URL,
    // Get current access token from Keycloak auth object
    //
    // This has to be a lambda: if we passed a token value, it would become
    // stale as soon as it is refreshed (which happens every few minutes with
    // well-configured OpenID servers)
    accessToken: () => auth?.getAccessToken() as string,
  });

  const api = create({
    baseURL: process.env.API_BASE_URL,
  });

  setupInterceptors(api, auth);

  const apiClient: ApiClient = {
    auth: createAuthEndpoint(api),
    healthRecord: new HealthRecordApi(kairosCitizenConfiguration),
    preventionAct: createPreventionActEndpoint(api),
    vaccinationAct: new VaccinationActApi(kairosCitizenConfiguration),
    vaccine: createVaccineEndpoint(api),
    profileLink: createProfileLinkEndpoint(api),
    account: createAccountEndpoint(api),
    healthRecordAccessControl: createHealthRecordAccessControlEndpoint(api),
    notification: createNotificationEndpoint(api),
    country: createCountryEndpoint(api),
    eventLog: createEventLogEndpoint(api),
    medicalOffice: createMedicalOfficeEndpoint(api),
    healthProfile: createHealthProfileEndpoint(api),
    sharingToken: new RecordSharingApi(kairosCitizenConfiguration),
  };

  return apiClient;
};
