const { locales, defaultLocale } = require("@config");
const hoistNonReactStatics = require("hoist-non-react-statics");

module.exports = {
  locales,
  defaultLocale,
  localeDetection: true,
  staticsHoc:
    hoistNonReactStatics /* https://github.com/vinissimus/next-translate/issues/464 */,
  pages: {
    "*": ["common", "yup"],
    "/authent/login": ["auth"],
    "/authent/signup": ["auth"],
    "/authent/verification": ["auth"],
    "/citizen/profiles": ["profiles"],
    "/citizen/profiles/new": ["profiles"],
    "/citizen/profiles/[healthRecordId]/edit": ["profiles"],
    "/citizen/profiles/[healthRecordId]/vaccination": [
      "profiles",
      "preventionAct",
    ],
    "/citizen/profiles/[healthRecordId]/events": ["profiles", "events"],
    "/citizen/profiles/[healthRecordId]/share": ["profiles", "share", "auth"],
    "/citizen/profiles/[healthRecordId]/share-token": [
      "profiles",
      "share",
      "auth",
    ],
    "/citizen/profiles/[healthRecordId]/health-profile": [
      "profiles",
      "healthProfile",
    ],
    "/citizen/profiles/[healthRecordId]/diagnosis": ["profiles", "diagnosis"],
    "/citizen/profiles/[healthRecordId]/vaccination/[preventionActId]": [
      "profiles",
      "preventionAct",
      "medicalOffice",
    ],
    "/citizen/profiles/[healthRecordId]/vaccination/[preventionActId]/edit": [
      "profiles",
      "preventionAct",
      "medicalOffice",
    ],
    "/citizen/profiles/[healthRecordId]/vaccination/new": [
      "profiles",
      "preventionAct",
      "medicalOffice",
    ],
    "/citizen/share/add": ["profiles", "share"],
    "/citizen/account": ["account"],
    "/citizen/account/open": ["account"],
    "/citizen/consents": ["consents", "profiles"],
  },
};
