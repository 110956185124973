import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { LoginType } from "pages/authent/login";
import { IRole } from "types/data/enums/IRole";
import { IHealthRecordAccessControl } from "types/data/IHealthRecordAccessControl";
import yup from "utils/yup";
import { Asserts } from "yup";

export const createRightSchema = yup.object({
  loginType: yup.string().oneOf(["email", "phone"]).defined(),
  email: yup
    .string()
    .email()
    .when("loginType", (loginType: LoginType, schema: any) => {
      if (loginType === "email") {
        return schema.defined();
      }
    }),
  phone: yup.string().when("loginType", (loginType: LoginType, schema: any) => {
    if (loginType === "phone") {
      return schema.defined();
    }
  }),
  role: yup.mixed<Omit<IRole, "owner">>().required(),
});

export interface ApiHealthRecordAccessControlClient {
  all: (
    healthRecordId: string
  ) => Promise<
    ApiResponse<
      { health_record_access_controls: IHealthRecordAccessControl[] },
      ApiError
    >
  >;
  create: (
    healthRecordId: string,
    values: Asserts<typeof createRightSchema>
  ) => Promise<
    ApiResponse<
      { health_record_access_control: IHealthRecordAccessControl },
      ApiError
    >
  >;
  delete: (
    healthRecordId: string,
    healthRecordAccessControlId: string
  ) => Promise<
    ApiResponse<
      { health_record_access_control: IHealthRecordAccessControl },
      ApiError
    >
  >;
}

export const createHealthRecordAccessControlEndpoint = (
  api: ApisauceInstance
): ApiHealthRecordAccessControlClient => {
  return {
    all: (healthRecordId) =>
      api.get(
        `/health_records/${healthRecordId}/health_record_access_controls`
      ),
    create: (healthRecordId, values) => {
      return api.post(
        `/health_records/${healthRecordId}/health_record_access_controls`,
        values
      );
    },
    delete: (healthRecordId, healthRecordAccessControlId) => {
      return api.delete(
        `/health_records/${healthRecordId}/health_record_access_controls/${healthRecordAccessControlId}`
      );
    },
  };
};
