import { Link } from "@syadem/ui-components";
import styles from "@theme/components/layouts/components/PublicHeader.module.css";
import { LanguageSelect } from "components/LanguageSelect";
import { Logo } from "components/Logo";
import { NextLink } from "components/NextLink";
import * as React from "react";

export interface PublicHeaderProps {
  className?: string;
}

export const PublicHeader: React.FC<PublicHeaderProps> = () => {
  return (
    <header className={styles.publicHeader}>
      <NextLink href="/">
        <Link>
          <Logo className={styles.logo} />
        </Link>
      </NextLink>
      <LanguageSelect className={styles.select} />
    </header>
  );
};
