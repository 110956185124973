import { ApiError } from "api/api";
import { PreventionActFilter } from "api/mirage/utils/filters";
import { ApiResponse, ApisauceInstance } from "apisauce";
import qs from "qs";
import { IInjectionLocation } from "types/data/enums/IInjectionLocation";
import { IInjectionMethod } from "types/data/enums/IInjectionMethod";
import { IValidationMethod } from "types/data/enums/IValidationMethod";
import { IPreventionAct } from "types/data/IPreventionAct";
import { dayjs } from "utils/dayjs";
import yup from "utils/yup";
import { Asserts } from "yup";
import { validationSchema } from "./../../components/PreventionActList";

export const newPreventionActSchema = yup.object({
  performed_on: yup
    .date()
    .min(dayjs.utc("1900-01-01").toDate())
    .max(dayjs.utc().startOf("day").toDate())
    .required(),
  metadata: yup.object({
    batch_number: yup.string(),
    serial_number: yup.string(),
    expiration_date: yup
      .date()
      .nullable()
      .min(dayjs.utc("1900-01-01").toDate()),
    booster: yup.boolean(),
    injection_method: yup.mixed<IInjectionMethod>(),
    injection_location: yup.mixed<IInjectionLocation>(),
    gtin: yup.string(),
  }),
  country_code: yup.string(),
  vaccine_id: yup.string().nullable().required(),
  consultation_attributes: yup.object({
    id: yup.string().nullable(),
    medical_office_id: yup.string().required(),
  }),
  validation_method: yup.mixed<IValidationMethod>(),
  provided_by_patient: yup.boolean(),
});

export type FilterParams = Partial<Record<PreventionActFilter, string>>;

export interface ApiPreventionActClient {
  all: (
    healthRecordId: string,
    filters: FilterParams
  ) => Promise<ApiResponse<{ prevention_acts: IPreventionAct[] }, ApiError>>;
  create: (
    healthRecordId: string,
    values: Partial<Asserts<typeof newPreventionActSchema>>
  ) => Promise<ApiResponse<{ prevention_act: IPreventionAct }, ApiError>>;
  get: (
    preventionActId: string
  ) => Promise<ApiResponse<{ prevention_act: IPreventionAct }, ApiError>>;
  update: (
    preventionActId: string,
    values: Partial<Asserts<typeof newPreventionActSchema>>
  ) => Promise<ApiResponse<{ prevention_act: IPreventionAct }, ApiError>>;
  validate: (
    preventionActId: string,
    values: Asserts<typeof validationSchema>
  ) => Promise<ApiResponse<{ prevention_act: IPreventionAct }, ApiError>>;
  delete: (
    preventionActId: string
  ) => Promise<ApiResponse<{ prevention_act: IPreventionAct }, ApiError>>;
  importEuGreenPass: (
    healthRecordId: string,
    qrCode: string
  ) => Promise<ApiResponse<{ prevention_act: IPreventionAct }, ApiError>>;
}

export const createPreventionActEndpoint = (
  api: ApisauceInstance
): ApiPreventionActClient => {
  return {
    all: (healthRecordId, filters) =>
      api.get(
        `/health_records/${healthRecordId}/prevention_acts${
          filters
            ? `?${qs.stringify(
                { filter: filters },
                { arrayFormat: "brackets" }
              )}`
            : ""
        }`
      ),
    create: (healthRecordId, values) => {
      return api.post(`/health_records/${healthRecordId}/prevention_acts`, {
        prevention_act: values,
      });
    },
    get: (preventionActId) => {
      return api.get(`/prevention_acts/${preventionActId}`);
    },
    update: (preventionActId, values) => {
      return api.put(`/prevention_acts/${preventionActId}`, {
        prevention_act: values,
      });
    },
    validate: (preventionActId, values) => {
      return api.post(`/prevention_acts/${preventionActId}/validate`, {
        prevention_act: values,
      });
    },
    delete: (preventionActId) => {
      return api.delete(`/prevention_acts/${preventionActId}`);
    },
    importEuGreenPass: (healthRecordId, qrCode) => {
      return api.post(
        `/health_records/${healthRecordId}/prevention_acts/import_eu_greenpass`,
        {
          qr_code_string: qrCode,
        }
      );
    },
  };
};
