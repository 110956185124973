import { NextPageContext } from "next";
import useTranslationNext from "next-translate/useTranslation";
import { parseCookies, setCookie } from "nookies";

const cookieName = "NEXT_LOCALE";

export const useTranslation = useTranslationNext;

export const hasSavedLanguage = () => {
  return !!getSavedLanguage();
};

export const getSavedLanguage = () => {
  const cookies = parseCookies();
  return cookies[cookieName];
};

export const saveLanguage = (language: string, ctx?: NextPageContext) => {
  return setCookie(ctx, cookieName, language, {
    maxAge: 30 * 24 * 60 * 60,
    path: "/",
  });
};

export const getCurrentLocale = () => {
  if (typeof window !== "undefined") {
    return document.documentElement.lang;
  }
  throw new Error("Can't get current locale server-side");
};

export const getLocaleForPath = (
  locale?: string,
  defaultLocale?: string
): string /* => /fr */ => {
  if (!locale || !defaultLocale) {
    return "";
  }
  if (locale === defaultLocale) {
    return "";
  }
  return `/${locale}`;
};
