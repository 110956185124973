import config from "@config";
import NextHead from "next/head";
//import OGDefault from '../../images/og_default.png';
import { useRouter } from "next/router";
import React from "react";
import { getLocaleForPath } from "utils/i18n";
import { getHostname, getThemeAssetPath } from "utils/next";

const defaultRobotIndex = true;
const defaultRobotFollow = true;

interface HeadProps {
  title?: string;
  description?: string;
  url?: string;
  canonical?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  index?: boolean;
  follow?: boolean;
}

export const Head: React.FunctionComponent<HeadProps> = ({
  index = defaultRobotIndex,
  follow = defaultRobotFollow,
  ...props
}) => {
  const router = useRouter();

  const url = new URL(
    `${getHostname()}${getLocaleForPath(router.locale, router.defaultLocale)}${
      router.asPath
    }`
  );
  const strippedAbsoluteURL = `${url.protocol}//${url.host}${url.pathname}`; // removed query strings
  const defaultOGURL = strippedAbsoluteURL;

  const buildRobots = (index: boolean, follow: boolean): string => {
    if (process.env.SEO_NO_INDEX === "true") {
      return "noindex,nofollow";
    }
    return [index ? "index" : "noindex", follow ? "follow" : "nofollow"]
      .filter((i) => !!i)
      .join(",");
  };

  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{config.meta?.title?.(config.appName, props.title)}</title>
      <meta
        name="description"
        content={props.description || config.meta?.description}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />

      {/* Robots */}
      <meta name="robots" content={buildRobots(index, follow)} />

      {/* Canonical */}
      <link rel="canonical" href={props.canonical || strippedAbsoluteURL} />

      {/* PWA */}
      <meta content={config.appName} name="application-name" />
      <link rel="manifest" href={getThemeAssetPath("manifest.json")} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={config.meta?.theme?.appleStatusBarColor}
      />
      <meta name="apple-mobile-web-app-title" content={config.appName} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta
        name="msapplication-config"
        content={getThemeAssetPath("browserconfig.xml")}
      />
      <meta
        name="msapplication-TileColor"
        content={config.meta?.theme?.microsoftTileColor}
      />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content={config.meta?.theme?.browserBarColor} />

      {/* Icons */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={getThemeAssetPath("apple-touch-icon.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={getThemeAssetPath("favicon-32x32.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={getThemeAssetPath("favicon-16x16.png")}
      />
      <link
        rel="mask-icon"
        href={getThemeAssetPath("safari-pinned-tab.svg")}
        color={config.meta?.theme?.safariPinnedTabColor}
      />
      <link rel="shortcut icon" href={getThemeAssetPath("favicon.ico")} />

      {/* OpenGraph */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={props.url || defaultOGURL} />
      <meta property="og:title" content={props.title || ""} />
      <meta
        property="og:description"
        content={props.description || config.meta?.description}
      />
      <meta name="twitter:site" content={props.url || defaultOGURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content={props.image || config.meta?.image?.src}
      />
      <meta
        property="og:image"
        content={props.image || config.meta?.image?.src}
      />
      {(props.imageWidth || config.meta?.image?.width) && (
        <meta
          property="og:image:width"
          content={props.imageWidth || config.meta?.image?.width}
        />
      )}
      {(props.imageHeight || config.meta?.image?.height) && (
        <meta
          property="og:image:height"
          content={props.imageHeight || config.meta?.image?.height}
        />
      )}
    </NextHead>
  );
};
