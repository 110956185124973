import axios, { AxiosError } from "axios";
import React, { Dispatch, useEffect } from "react";
import { QueryObserverResult } from "react-query";
import { isNotEmpty } from "utils/array";

/*
  Error codes, from highest priority to lowest
*/
const errorPriorities = [404, 400, 401, 500]; // Left is highest priority

/*
  Takes an error code and returns a priority from 0 to 9999
  0 = highest priority
  9999 = lowest priority
*/
const getErrorCodePriority = (code: number) => {
  const index = errorPriorities.indexOf(code);
  if (index !== -1) {
    return index;
  }
  return 9999; // defaults to lowest priority
};

/*
  Takes an array of codes, returns the highest priority one
*/
const getDominantErrorCode = (codes: number[]) => {
  return codes.sort((a, b) => {
    return getErrorCodePriority(a) - getErrorCodePriority(b);
  })[0];
};

/*
  Hook that manages page errors for us.
  Takes queries, checks if they error, and displays a global error page (in _app) if needed.
  This is meant to be used on pages that displays a static skeleton and make queries client-side (https://nextjs.org/docs/basic-features/data-fetching#fetching-data-on-the-client-side).
  If you're doing server-side queries you should probably instead return `notFound: true` from getServerSideProps
*/

export const usePageErrors = (
  ...queries: QueryObserverResult<unknown, AxiosError<any> | Response>[]
) => {
  const { setError } = useGlobalError();
  const queryErrors = queries
    .filter((query) => query.isError)
    .map((query) =>
      axios.isAxiosError(query.error)
        ? query.error.response?.status
        : query.error?.status
    )
    .filter(isNotEmpty);
  const errorCode = getDominantErrorCode(queryErrors);

  useEffect(() => {
    setError(errorCode);
  }, [errorCode, setError]);
};

export const GlobalErrorContext = React.createContext<{
  error?: number;
  setError: Dispatch<number | undefined>;
}>({
  error: undefined,
  setError: () => null,
});
export const GlobalErrorProvider = GlobalErrorContext.Provider;
export const useGlobalError = () => React.useContext(GlobalErrorContext);
