import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import {
  IHealthProfileItem,
  IHealthProfileSection,
  IHealthProfileStatusItem,
  IHealthProfileVaccination,
} from "../../types/data/IHealthProfile";
import { IHealthRecord } from "./../../types/data/IHealthRecord";

export interface ApiHealthProfileClient {
  get: (
    healthRecordId: string
  ) => Promise<ApiResponse<IHealthProfileSection[], ApiError>>;
  update: (
    healthRecordId: string,
    healthProfile: IHealthProfileItem[]
  ) => Promise<
    ApiResponse<
      {
        health_record: IHealthRecord;
      },
      ApiError
    >
  >;
  getStatus: (healthRecordId: string) => Promise<
    ApiResponse<
      {
        screening: IHealthProfileStatusItem[];
        vaccination: IHealthProfileVaccination;
      },
      ApiError
    >
  >;
}

export const createHealthProfileEndpoint = (
  api: ApisauceInstance
): ApiHealthProfileClient => {
  return {
    get: (healthRecordId: string) =>
      api.get(`/health_profiles/${healthRecordId}/survey`),
    update: (healthRecordId: string, healthProfile: IHealthProfileItem[]) =>
      api.patch(`/health_profiles/${healthRecordId}`, {
        health_record: {
          health_profile: healthProfile,
        },
      }),
    getStatus: (healthRecordId: string) =>
      api.get(`/health_profiles/${healthRecordId}/status`),
  };
};
