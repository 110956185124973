import config from "@config";
import qs from "qs";

/*
  With Static Optimization (https://nextjs.org/docs/advanced-features/automatic-static-optimization)
  we don't get to access query params on the first render, so this is a workaround to parse them from the asPath
*/
export const getQueryFromPath = (asPath: string) => {
  const url = new URL(`https://dummy.com${asPath}`);
  const query = qs.parse(url.search);
  return query;
};

export const isServer = () => typeof window === "undefined";

export const getHostname = (): string /* => http://domain.com */ => {
  let hostname = process.env.NEXT_PUBLIC_HOSTNAME;
  if (!hostname) {
    throw new Error(
      "You need to specify a HOSTNAME env variable with the following format: http(s?)://(www.?)myhostname(:port?).com(/?)"
    );
  }
  if (hostname.indexOf("http") !== 0) {
    hostname = `https://${hostname}`;
  }
  return hostname;
};

/*
  Return the public path for a theme asset (located in config/theme/assets).
*/
export const getThemeAssetPath = (asset: string) => {
  const basePath = config.basePath || "";
  return basePath + "/@theme/" + asset;
};
