import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { IVaccine } from "types/data/IVaccine";

export interface ApiVaccineClient {
  all: () => Promise<ApiResponse<{ prevention_methods: IVaccine[] }, ApiError>>;
  findByDataMatrix: (
    gtin: string
  ) => Promise<ApiResponse<{ vaccine: IVaccine }, ApiError>>;
}

export const createVaccineEndpoint = (
  api: ApisauceInstance
): ApiVaccineClient => {
  return {
    all: () => api.get(`/prevention_methods`),
    findByDataMatrix: (gtin) => {
      return api.post(`/prevention_methods/find_by_data_matrix`, {
        vaccine: {
          id: gtin,
        },
      });
    },
  };
};
