import { locales } from "@config";
import { Select } from "@syadem/ui-components";
import { useRouter } from "next/router";
import * as React from "react";
import { useQueryClient } from "react-query";
import { dayjs } from "utils/dayjs";
import { saveLanguage, useTranslation } from "utils/i18n";

export interface LanguageSelectProps {
  className?: string;
  dropdownPosition?: "top" | "bottom";
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  className,
  dropdownPosition,
}) => {
  const { t, lang } = useTranslation();
  const router = useRouter();
  const queryClient = useQueryClient();

  const languageSelectValues = React.useMemo(
    () =>
      locales.map((locale) => {
        return {
          id: locale,
          label: t(`common:languages.${locale}`),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lang, t]
  );

  if (locales.length < 2) {
    return null;
  }

  return (
    <Select
      id="languageSelect"
      values={languageSelectValues}
      defaultValue={lang}
      onChange={async (changedLang) => {
        const newLang = String(changedLang);
        if (lang !== newLang) {
          saveLanguage(newLang);
          dayjs.locale(newLang);
          await router.push(
            { pathname: router.route, query: { ...router.query } },
            router.asPath,
            { locale: newLang }
          );
          queryClient.invalidateQueries();
        }
      }}
      dropdownPosition={dropdownPosition}
      className={className}
      testId="language-select"
    ></Select>
  );
};
