import { ApiOkResponse, ApiResponse } from "apisauce";
import { ApiError } from "./../api/api";

/*
  Make sure data is available on the Api Response
*/
interface ApiOkResponseWithData<T> extends ApiOkResponse<T> {
  data: T;
}

export const throwOnError = <T>(res: ApiResponse<T, ApiError>) => {
  /* React-query needs throwing to handle errors properly */
  if (!res.ok) {
    const error = res.originalError;
    throw error;
  }
  if (typeof res.data === "undefined") {
    throw new Error("No data returned from endpoint");
  }
  return res as ApiOkResponseWithData<T>;
};
