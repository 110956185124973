import styles from "@theme/components/layouts/PublicLayout.module.css";
import clsx from "clsx";
import * as React from "react";
import { PublicHeader } from "./components/PublicHeader";

export interface PublicLayoutProps {
  className?: string;
}

export const PublicLayout: React.FC<PublicLayoutProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(styles.publicLayout, className)} {...rest}>
      <PublicHeader />
      <div className={styles.content}>
        <div className={styles.childrenWrapper}>{children}</div>
      </div>
    </div>
  );
};
