import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import qs from "qs";
import { IEventLog } from "./../../types/data/IEventLog";
import {
  IPaginationMeta,
  IPaginationQueryParams,
} from "./../../types/data/IPagination";

export interface ApiEventLogClient {
  all: (
    healthRecordId: string,
    params: IPaginationQueryParams
  ) => Promise<
    ApiResponse<{ event_logs: IEventLog[]; meta?: IPaginationMeta }, ApiError>
  >;
}

export const createEventLogEndpoint = (
  api: ApisauceInstance
): ApiEventLogClient => {
  return {
    all: (healthRecordId, params) =>
      api.get(
        `/health_records/${healthRecordId}/event_logs${
          params ? `?${qs.stringify(params, { arrayFormat: "brackets" })}` : ""
        }`
      ),
  };
};
