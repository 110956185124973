import pick from "pick-deep";
import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";
import { dayjs } from "./dayjs";
import { getCurrentLocale } from "./i18n";
import { dotJoin } from "./string";

/*
  yup.date.format("DD/MM/YYYY")
  Check if the input can be parsed as a date following a specific format.
  Useful for date strings like (DD/MM/YYYY)
*/
yup.addMethod<yup.DateSchema>(
  yup.date,
  "format",
  function (format, parseStrict) {
    return this.transform(function (value, originalValue) {
      if (this.isType(value)) return value;
      value = dayjs(originalValue, format, parseStrict);
      return value.isValid() ? value.toDate() : new Date("");
    });
  }
);

declare module "yup" {
  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    format(format: string): DateSchema<TType, TContext>;
  }
}

/*
  Setup Yup locales using https://github.com/jquense/yup/blob/e6dbef1596e57c70d90668e77d5216986e15ae08/README.md#using-a-custom-locale-dictionary
  based on https://github.com/jquense/yup/blob/e6dbef1596e57c70d90668e77d5216986e15ae08/src/locale.ts
*/
yup.setLocale({
  mixed: {
    default: ({ path }: { path: string }) => ({
      key: "mixed.default",
      values: { path },
    }),
    required: ({ path }: { path: string }) => ({
      key: "mixed.required",
      values: { path },
    }),
    oneOf: ({ path, values }: { path: string; values: string }) => ({
      key: "mixed.oneOf",
      values: { path, values },
    }),
    notOneOf: ({ path, values }: { path: string; values: string }) => ({
      key: "mixed.notOneOf",
      values: { path, values },
    }),
    defined: ({ path }: { path: string }) => ({
      key: "mixed.defined",
      values: { path },
    }),
    notType: ({ path, type }: { path: string; type: string }) => {
      switch (type) {
        case "date": {
          return {
            key: "date.invalid",
            values: { path },
          };
        }
      }
    },
  },
  string: {
    length: ({ path, length }: { path: string; length: number }) => ({
      key: "string.length",
      values: { path, length },
    }),
    min: ({ path, min }: { path: string; min: number }) => ({
      key: "string.min",
      values: { path, min },
    }),
    max: ({ path, max }: { path: string; max: number }) => ({
      key: "string.max",
      values: { path, max },
    }),
    matches: ({ path, regex }: { path: string; regex: RegExp }) => ({
      key: "string.matches",
      values: { path, regex },
    }),
    email: ({ path }: { path: string }) => ({
      key: "string.email",
      values: { path },
    }),
    url: ({ path }: { path: string }) => ({
      key: "string.url",
      values: { path },
    }),
    uuid: ({ path }: { path: string }) => ({
      key: "string.uuid",
      values: { path },
    }),
    trim: ({ path }: { path: string }) => ({
      key: "string.trim",
      values: { path },
    }),
    lowercase: ({ path }: { path: string }) => ({
      key: "string.lowercase",
      values: { path },
    }),
    uppercase: ({ path }: { path: string }) => ({
      key: "string.uppercase",
      values: { path },
    }),
  },
  number: {
    min: ({ path, min }: { path: string; min: number }) => ({
      key: "number.min",
      values: { path, min },
    }),
    max: ({ path, max }: { path: string; max: number }) => ({
      key: "number.max",
      values: { path, max },
    }),
    lessThan: ({ path, less }: { path: string; less: number }) => ({
      key: "number.lessThan",
      values: { path, less },
    }),
    moreThan: ({ path, more }: { path: string; more: number }) => ({
      key: "number.moreThan",
      values: { path, more },
    }),
    positive: ({ path }: { path: string }) => ({
      key: "number.positive",
      values: { path },
    }),
    negative: ({ path }: { path: string }) => ({
      key: "number.negative",
      values: { path },
    }),
    integer: ({ path }: { path: string }) => ({
      key: "number.integer",
      values: { path },
    }),
  },
  date: {
    min: ({ path, min }: { path: string; min: string | Date }) => ({
      key: "date.min",
      values: {
        path,
        min: dayjs(min).locale(getCurrentLocale()).format("L"),
      },
    }),
    max: ({ path, max }: { path: string; max: string | Date }) => ({
      key: "date.max",
      values: {
        path,
        max: dayjs(max).locale(getCurrentLocale()).format("L"),
      },
    }),
  },
  boolean: {
    isValue: ({ path, value }: { path: string; value: string }) => ({
      key: "boolean.isValue",
      values: { path, value },
    }),
  },
  object: {
    noUnknown: ({ path }: { path: string }) => ({
      key: "object.noUnknown",
      values: { path },
    }),
  },
  array: {
    min: ({ path, min }: { path: string; min: number }) => ({
      key: "date.min",
      values: { path, min },
    }),
    max: ({ path, max }: { path: string; max: number }) => ({
      key: "date.max",
      values: { path, max },
    }),
    length: ({ path, length }: { path: string; length: number }) => ({
      key: "date.length",
      values: { path, length },
    }),
  },
});

export default yup;

export const getKeysFromYupSchema = <Schema extends yup.AnyObjectSchema>(
  schema: Schema,
  rootKey?: string
): string[] => {
  const maxFlatDepth = 10;
  return Object.keys(schema.fields as { [key: string]: any })
    .map((fieldKey) => {
      if (schema.fields[fieldKey] instanceof yup.ObjectSchema) {
        return getKeysFromYupSchema(
          schema.fields[fieldKey],
          dotJoin(rootKey, fieldKey)
        );
      }
      return dotJoin(rootKey, fieldKey);
    })
    .flat(maxFlatDepth);
};

/*
  Takes an object, a yup schema, and returns a new object that only 
  contains keys that exists in the yup schema
*/
export const mapDataToYupSchema = <Schema extends yup.AnyObjectSchema>(
  data: any,
  schema: Schema
): Schema => {
  const keys = getKeysFromYupSchema(schema);
  // @ts-ignore pick() expects "object" type as first argument which is unusual
  return pick(data, keys);
};
