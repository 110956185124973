import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import qs from "qs";
import { IMedicalOffice } from "./../../types/data/IMedicalOffice";
import {
  IPaginationMeta,
  IPaginationQueryParams,
} from "./../../types/data/IPagination";

export interface MedicalOfficeFilterParams extends IPaginationQueryParams {
  filter?: {
    name?: string;
  };
}

export interface ApiMedicalOfficeClient {
  all: (
    params: MedicalOfficeFilterParams
  ) => Promise<
    ApiResponse<
      { medical_offices: IMedicalOffice[]; meta?: IPaginationMeta },
      ApiError
    >
  >;
  allByUser: () => Promise<
    ApiResponse<{ medical_offices: IMedicalOffice[] }, ApiError>
  >;
  associateToUser: (
    medicalOfficeId: string
  ) => Promise<ApiResponse<{ medical_office: IMedicalOffice }, ApiError>>;
  delete: (
    medicalOfficeId: string
  ) => Promise<ApiResponse<{ medical_office: IMedicalOffice }, ApiError>>;
}

export const createMedicalOfficeEndpoint = (
  api: ApisauceInstance
): ApiMedicalOfficeClient => {
  return {
    all: (params) =>
      api.get(
        `/medical_offices${
          params ? `?${qs.stringify(params, { arrayFormat: "brackets" })}` : ""
        }`
      ),
    allByUser: () => api.get(`/health_professionals/medical_offices`),
    associateToUser: (medicalOfficeId) =>
      api.post(`/health_professionals/medical_offices/${medicalOfficeId}`),
    delete: (medicalOfficeId) => {
      return api.delete(
        `/health_professionals/medical_offices/${medicalOfficeId}`
      );
    },
  };
};
