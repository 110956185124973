import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { IProfileLink } from "types/data/IProfileLink";

export interface ApiProfileLinkClient {
  all: (
    healthRecordId: string
  ) => Promise<ApiResponse<{ profile_links: IProfileLink[] }, ApiError>>;
  create: (
    healthRecordId: string
  ) => Promise<ApiResponse<{ profile_link: IProfileLink }, ApiError>>;
  get: (
    profileLinkId: string
  ) => Promise<ApiResponse<{ profile_link: IProfileLink }, ApiError>>;
  delete: (
    healthRecordId: string,
    profileLinkId: string
  ) => Promise<ApiResponse<{ profile_link: IProfileLink }, ApiError>>;
}

export const createProfileLinkEndpoint = (
  api: ApisauceInstance
): ApiProfileLinkClient => {
  return {
    all: (healthRecordId) => api.get(`/health_records/${healthRecordId}/links`),
    create: (healthRecordId) => {
      return api.post(`/health_records/${healthRecordId}/links`);
    },
    get: (profileLinkId) => {
      return api.get(`/profile_links/${profileLinkId}`);
    },
    delete: (healthRecordId, profileLinkId) => {
      return api.delete(
        `/health_records/${healthRecordId}/links/${profileLinkId}`
      );
    },
  };
};
