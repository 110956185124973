import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { IAccount } from "types/data/IAccount";
export interface ApiAccountClient {
  create: (
    values: IAccount
  ) => Promise<ApiResponse<{ account: IAccount }, ApiError>>;
  getCurrent: () => Promise<ApiResponse<{ account: IAccount }, ApiError>>;
  getAccountById: (
    id: string
  ) => Promise<ApiResponse<{ account: IAccount }, ApiError>>;
  update: (
    id: string,
    values: Partial<IAccount>
  ) => Promise<ApiResponse<{ account: IAccount }, ApiError>>;
  delete: () => Promise<ApiResponse<{ account: IAccount }, ApiError>>;
  startOTPUpdate: (values: {
    phone: string | null;
    email: string | null;
  }) => Promise<ApiResponse<{ status: string }, ApiError>>;
  finishOTPUpdate: (values: {
    otp: string;
  }) => Promise<ApiResponse<{ status: string }, ApiError>>;
}

export const createAccountEndpoint = (
  api: ApisauceInstance
): ApiAccountClient => {
  return {
    create: (values) => {
      return api.post("/accounts", { account: values });
    },
    getCurrent: () => {
      return api.get("/account");
    },
    getAccountById: (id) => {
      return api.get(`/accounts/${id}`);
    },
    update: (id, values) => {
      return api.put(`/accounts/${id}`, { account: values });
    },
    delete: () => {
      return api.delete("/accounts");
    },
    startOTPUpdate: (values) => {
      return api.post("/accounts/send_channel_verification_otp", {
        account: values,
      });
    },
    finishOTPUpdate: (values) => {
      return api.post("/accounts/verify_channel_with_otp", values);
    },
  };
};
