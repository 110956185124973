import config from "@config";
import { ApisauceInstance } from "apisauce";
import { AxiosRequestConfig } from "axios";
import { getCurrentLocale } from "./../utils/i18n";
import { AuthContext, getScopeFromWindow } from "./auth/auth";
/*
  Auth is disabled in development and the API needs to know the current user scope.
  Here we infer it from the current url
*/
const setupRequestScope = (req: AxiosRequestConfig) => {
  const scope = getScopeFromWindow();
  // handle issue if req.params is undefined
  req.params = { ...req.params, type: scope };
};

const setupRequestLang = (req: AxiosRequestConfig) => {
  req.headers = {
    ...req.headers,
    "Accept-language": getCurrentLocale(),
    ...(config.source && { source: config.source }),
  };
};

export const setupInterceptors = (
  api: ApisauceInstance,
  auth?: AuthContext
) => {
  api.axiosInstance.interceptors.request.use((req) => {
    if (
      process.env.SKIP_AUTHENTIFICATION === "true" &&
      typeof window !== "undefined"
    ) {
      setupRequestScope(req);
    }

    setupRequestLang(req);

    if (auth) {
      const accessToken = auth.getAccessToken();
      if (accessToken) {
        req.headers = {
          ...req.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }
    }

    return req;
  });

  api.axiosInstance.interceptors.response.use(
    async (res) => {
      return res;
    },
    (error) => {
      const redirectStatus = error.response?.status === 403;
      const redirectLocation = error.response?.headers?.location;
      const shouldRedirect = redirectStatus && redirectLocation;

      if (shouldRedirect) {
        window.location = redirectLocation;
      }
      return error;
    }
  );
};
