import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { ICountry } from "./../../types/data/ICountry";
export interface ApiCountryClient {
  all: () => Promise<ApiResponse<ICountry[], ApiError>>;
}

export const createCountryEndpoint = (
  api: ApisauceInstance
): ApiCountryClient => {
  return {
    all: () => {
      return api.get("/countries");
    },
  };
};
