import { ApiError } from "api/api";
import { ApiResponse, ApisauceInstance } from "apisauce";
import { INotification } from "types/data/INotification";
import { INotificationBase } from "./../../types/data/INotification";
export interface ApiNotificationClient {
  create: (
    values: INotificationBase
  ) => Promise<ApiResponse<{ notification: INotification }, ApiError>>;
  update: (
    id: string,
    values: INotificationBase
  ) => Promise<ApiResponse<{ notification: INotification }, ApiError>>;
  delete: (
    id: string
  ) => Promise<ApiResponse<{ notification: INotification }, ApiError>>;
}

export const createNotificationEndpoint = (
  api: ApisauceInstance
): ApiNotificationClient => {
  return {
    create: (values) => {
      return api.post("/notifications", values);
    },
    update: (id, values) => {
      return api.put(`/notifications/${id}`, values);
    },
    delete: (id) => {
      return api.delete(`/notifications/${id}`);
    },
  };
};
