const config = {
  appName: "Digital Vaccination Card",
  target: "serverless",
  /*
    Specify your env keys here, so there's accessible from the app.
    Manually specifying env variables here avoids leaking private env vars to the app unintentionnally.

    Please avoid hardcoding values here and use `process.env.MY_VALUE`, since this file is versionned.
    Refer to the readme for more information about managing ENV vars.
  */
  env: {
    HOSTNAME: process.env.NEXT_PUBLIC_HOSTNAME,
    BASE_PATH: process.env.BASE_PATH,
    API_BASE_URL: process.env.API_BASE_URL,
    USE_MOCKED_API: process.env.USE_MOCKED_API,
    MOCKED_API_CACHE_KEY: process.env.MOCKED_API_CACHE_KEY,
    SENTRY_DSN: process.env.SENTRY_DSN,
    ENVIRONMENT: process.env.ENVIRONMENT, // "production", "staging", "review/feature-test"...
    RELEASE: process.env.RELEASE, // "v1.0.1..."
    COUNTRIES_CACHE_KEY: process.env.COUNTRIES_CACHE_KEY,
    SKIP_AUTHENTIFICATION: process.env.SKIP_AUTHENTIFICATION,
  },
  meta: {
    title: (appName, pageTitle) =>
      [appName, pageTitle].filter((i) => !!i).join(" | "),
    description: "I'm a default description",
    image: {
      src: `${process.env.BASE_PATH}/@theme/logo.svg`,
    },
    theme: {
      safariPinnedTabColor: "#c81a1b",
      appleStatusBarColor: "#ffffff",
      microsoftTileColor: "#ffffff",
      browserBarColor: "#ffffff",
    },
  },
  /*
    Serve the app routes behind a basePath
    eg. domain.com/somebasepath/*
  */
  basePath: process.env.BASE_PATH,
  /*
    Define the available locales for the App
  */
  locales: ["en-gb", "fr"],
  /*
    Default locale on first visit
  */
  defaultLocale: "en-gb",
  customLocales: {
    "en-gb": {
      patients: {
        create: {
          allowCreatePatientLabel:
            "I certify that I have entered this information on the basis of the presentation of an identity document and that the patient has consented to the addition of his/her identification data to the Luxembourg Patient Identification Directory",
        },
      },
    },
    fr: {
      patients: {
        create: {
          allowCreatePatientLabel:
            "J'atteste avoir saisi ces informations sur la base de la présentation d'une pièce d'identité et que le patient a consenti à l'ajout des ses données d'identification au sein de l'Annuaire Référentiel d'Identification des patients du Luxembourg",
        },
      },
    },
  },
  phoneFormats: [
    {
      mask: "{+352} 600 000 000",
      placeholder: "+352 612 456 789",
      countryCode: "LU",
      text: "🇱🇺",
    },
    {
      mask: "{+33} 0 00 00 00 00",
      placeholder: "+33 6 12 34 56 78",
      countryCode: "FR",
      text: "🇫🇷",
    },
    {
      mask: "{+49} 000 000 000 00",
      placeholder: "+49 170 123 456 12",
      countryCode: "DE",
      text: "🇩🇪",
    },
    {
      mask: "{+32} 000 000 000",
      placeholder: "+32 470 123 456",
      countryCode: "BE",
      text: "🇧🇪",
    },
    {
      mask: "{+}00[0] 000 000 000 000",
      placeholder: "+33 6 12 34 56 78",
      countryCode: "OTHER",
      text: "🌍",
    },
  ],
  /*
    Media Queries variables
  */
  mediaQueries: {
    "--xlarge-viewport-up": "(min-width: 1440px)",
    "--large-viewport-up": "(min-width: 1200px)",
    "--medium-viewport-up": "(min-width: 1024px)",
    "--small-viewport-up": "(min-width: 800px)",
    "--xsmall-viewport-up": "(min-width: 600px)",
    "--xxsmall-viewport-up": "(min-width: 400px)",
    "--xxxsmall-viewport-up": "(min-width: 330px)",
  },
  userIdLength: 13,
  defaultCountryCode: "LU",
  links: {
    privacyAndTerms: "/privacyAndTerms",
    legal: "https://www.syadem.fr/mentions_legales.html",
    cookies: "",
    contact: "/contact",
  },
  features: {
    userCanCreateAndEditPreventionAct: true,
    citizenCanDeletePreventionAct: true,
    citizenCanCreateAndEditHealthRecord: true,
    citizenCanDeleteHealthRecord: true,
    citizenCanCreateAndEditHealthProfile: true,
    userCanAccessHealthProfile: true,
    userCanAccessDiagnosis: true,
    userCanAccessConsent: true,
    userCanLoginWithOpenId: true,
    userCanCreateAccountToLogin: true,
    userCanLoginWithPhoneOrEmail: true,
    userCanScanGreenPass: true,
    userCanConsentCovid: true,
    userCanSeeConsents: true,
  },
  source: "mon_espace_sante",
};

module.exports = config;
