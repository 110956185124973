import { Button, Link, Text, Title } from "@syadem/ui-components";
import styles from "@theme/pages/_error.module.css";
import { PublicLayout } from "components/layouts/PublicLayout";
import { Logo } from "components/Logo";
import { NextLink } from "components/NextLink";
import { Translate } from "next-translate";
import useTranslation from "next-translate/useTranslation";
import React from "react";

export interface ErrorLayoutProps {
  statusCode: number;
}

const getErrorMessage = (statusCode: number, t: Translate) => {
  switch (statusCode) {
    case 400:
      return t("common:errors.400");
    case 404:
      return t("common:errors.404");
    case 401:
      return t("common:errors.401");
    default:
      return t("common:errors.default");
  }
};

const ErrorLayout: React.FC<ErrorLayoutProps> = ({ statusCode }) => {
  const { t } = useTranslation();

  return (
    <PublicLayout>
      <div className={styles.container}>
        <NextLink href="/">
          <Link>
            <Logo className={styles.logo} />
          </Link>
        </NextLink>
        <Title as="p" className={styles.title} data-testid="errorTitle">
          {t("common:errors.title", { statusCode: statusCode })}
        </Title>
        <Text fontSize="large" className={styles.text}>
          {getErrorMessage(statusCode, t)}
        </Text>
        <NextLink href="/">
          <Button as="a" className={styles.button}>
            {t("common:backHome")}
          </Button>
        </NextLink>
      </div>
    </PublicLayout>
  );
};

export default ErrorLayout;
